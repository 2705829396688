import React from 'react';
import { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import { supabase } from '../lib/supabaseClient';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { track } from '../lib/mixpanel';



interface SubscriptionPlan {
    id: string;
    name: string;
    price: string;
    interval: 'day' | 'week' | 'month' | 'year';  // Updated to only allow valid Stripe intervals
    description: string;
  }

interface SubscriptionStatus {
    subscription_status: string | null;
    subscription_tier: string | null;
    subscription_end_date: string | null;  // Added this field
    first_question_used: boolean;
    first_answer_used: boolean;
  }

interface SubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

interface UserRealtimePayload {
    subscription_status: string | null;
    subscription_tier: string | null;
    subscription_end_date: string | null;  // Added this field
    first_question_used: boolean;
    first_answer_used: boolean;
  }

  const subscriptionPlans: SubscriptionPlan[] = [
    {
      id: 'prod_RJZFR2vaL5j4F7',
      name: 'Weekly',
      price: '$15',
      interval: 'week',
      description: 'Perfect for short-term interview prep'
    },
    {
      id: 'prod_RJZHKktLFifYLQ',
      name: 'Monthly',
      price: '$30',
      interval: 'month',
      description: 'Most popular option for job seekers'
    },
    {
      id: 'prod_RJZI8lfqF8Sx9F',
      name: '3 Months',  // Updated name
      price: '$75',
      interval: 'month',  // Changed from 'quarter' to 'month'
      description: 'Best value for serious preparation'
    }
  ];

  export const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ 
    isOpen, 
    onClose, 
    onSuccess 
  }) => {
    const monthlyPlanId = subscriptionPlans.find(p => p.name === 'Monthly')?.id ?? null;
const [selectedPlan, setSelectedPlan] = useState<string | null>(monthlyPlanId);
    const [isProcessing, setIsProcessing] = useState(false);

  // Calculate savings percentages
  const calculateSavings = (planPrice: string, planInterval: string) => {
    const weeklyPrice = 15; // Base price per week
    const price = parseInt(planPrice.replace('$', ''));
    
    let weeksInPlan = 0;
    if (planInterval === 'month') {
      weeksInPlan = planPrice === '$75' ? 12 : 4; // 3 months or 1 month
    }
    
    const equivalentWeeklyCost = (price / weeksInPlan);
    const savingsPercentage = Math.round(((weeklyPrice - equivalentWeeklyCost) / weeklyPrice) * 100);
    
    return savingsPercentage;
  };


    // Track modal open
  useEffect(() => {
    if (isOpen) {
      track('Subscription Modal Viewed', {
        source: window.location.pathname,
        timestamp: new Date().toISOString()
      });
    }
  }, [isOpen]);

  const handlePlanSelect = (planId: string) => {
    const plan = subscriptionPlans.find(p => p.id === planId);
    track('Subscription Plan Selected', {
      plan_id: planId,
      plan_name: plan?.name,
      plan_price: plan?.price,
      plan_interval: plan?.interval
    });
    setSelectedPlan(planId);
  };

  const handleModalClose = () => {
    track('Subscription Modal Closed', {
      closing_method: 'x_button',
      had_plan_selected: !!selectedPlan,
      selected_plan: selectedPlan ? subscriptionPlans.find(p => p.id === selectedPlan)?.name : null,
    });
    onClose();
  };

  const handleCancelClick = () => {
    track('Subscription Modal Closed', {
      closing_method: 'cancel_button',
      had_plan_selected: !!selectedPlan,
      selected_plan: selectedPlan ? subscriptionPlans.find(p => p.id === selectedPlan)?.name : null,
    });
    onClose();
  };
  
  const handleSubscribe = async (planId: string) => {
    const selectedPlanDetails = subscriptionPlans.find(p => p.id === planId);
    
    track('Subscribe Button Clicked', {
      plan_id: planId,
      plan_name: selectedPlanDetails?.name,
      plan_price: selectedPlanDetails?.price,
      plan_interval: selectedPlanDetails?.interval
    });

    setIsProcessing(true);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) throw new Error('No session found');

      const plan = subscriptionPlans.find(p => p.id === planId);
      if (!plan) throw new Error('Invalid plan selected');

      const quantity = plan.name === '3 Months' ? 3 : 1;

      const { data, error } = await supabase.functions.invoke('create-checkout-session', {
        body: { 
          productId: planId,
          quantity: quantity
        }
      });

      if (error) {
        track('Subscription Error', {
          error_type: 'checkout_creation_failed',
          error_message: error.message,
          plan_id: planId,
          plan_name: plan.name
        });
        throw error;
      }

      track('Checkout Session Created', {
        plan_id: planId,
        plan_name: plan.name,
        quantity: quantity
      });

      // Redirect to Stripe Checkout
      window.location.href = data.url;
    } catch (error: any) {
      console.error('Error creating checkout session:', error);
      track('Subscription Error', {
        error_type: 'checkout_creation_failed',
        error_message: error.message,
        plan_id: planId
      });
      alert('Failed to start checkout process. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-base-100 rounded-lg shadow-xl max-w-2xl w-full mx-4">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Choose Your Plan</h2>
            <button onClick={handleModalClose} className="btn btn-ghost btn-circle">
              <X />
            </button>
          </div>
          
          <div className="grid md:grid-cols-3 gap-6">
            {subscriptionPlans.map((plan) => {
              const savings = plan.interval === 'month' ? calculateSavings(plan.price, plan.interval) : 0;
              
              return (
                <div 
                  key={plan.id}
                  className={`relative border rounded-lg p-6 cursor-pointer transition-all
                    ${selectedPlan === plan.id ? 'border-primary shadow-lg' : 'border-base-300'}
                  `}
                  onClick={() => handlePlanSelect(plan.id)}
                >
                  {savings > 0 && (
                    <div className="absolute -top-3 -right-3 bg-primary text-primary-content text-xs font-bold px-2 py-1 rounded-full">
                      Save {savings}%
                    </div>
                  )}
                  {plan.name === 'Monthly' && (
                    <div className="absolute -top-3 -left-3 bg-secondary text-secondary-content text-xs font-bold px-2 py-1 rounded-full">
                      Most Popular
                    </div>
                  )}
                  <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                  <p className="text-2xl font-bold mb-2">{plan.price}</p>
                  <p className="text-sm text-base-content/70">per {plan.interval}</p>
                  <p className="mt-4 text-sm">{plan.description}</p>
                </div>
              );
            })}
          </div>

          <div className="mt-8 flex justify-end gap-4">
            <button 
              className="btn btn-ghost" 
              onClick={handleCancelClick}
              disabled={isProcessing}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              disabled={!selectedPlan || isProcessing}
              onClick={() => selectedPlan && handleSubscribe(selectedPlan)}
            >
              {isProcessing ? (
                <>
                  <span className="loading loading-spinner"></span>
                  Processing...
                </>
              ) : (
                'Subscribe Now'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const useSubscription = () => {
    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | null>(null);
    const [isLoading, setIsLoading] = useState(true);
  
    const fetchSubscriptionStatus = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user found');
  
        const { data, error } = await supabase
          .from('users')
          .select('subscription_status, subscription_tier, subscription_end_date, first_question_used, first_answer_used')
          .eq('id', user.id)
          .single();
  
        if (error) throw error;
  
        setSubscriptionStatus({
          subscription_status: data.subscription_status,
          subscription_tier: data.subscription_tier,
          subscription_end_date: data.subscription_end_date,  // Added this field
          first_question_used: data.first_question_used || false,
          first_answer_used: data.first_answer_used || false
        });
      } catch (error) {
        console.error('Error checking subscription:', error);
        setSubscriptionStatus(null);
      } finally {
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      fetchSubscriptionStatus();
  
      // Set up real-time subscription
      const setupSubscription = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;
  
        const subscription = supabase
          .channel(`public:users:id=eq.${user.id}`)
          .on('postgres_changes', 
            {
              event: '*',
              schema: 'public',
              table: 'users',
              filter: `id=eq.${user.id}`
            }, 
            (payload: RealtimePostgresChangesPayload<UserRealtimePayload>) => {
              if (payload.eventType === 'DELETE') return;
              
              const newData = payload.new;
              setSubscriptionStatus(current => ({
                subscription_status: newData.subscription_status,
                subscription_tier: newData.subscription_tier,
                subscription_end_date: newData.subscription_end_date,  // Added this field
                first_question_used: newData.first_question_used,
                first_answer_used: newData.first_answer_used
              }));
            }
          )
          .subscribe();
  
        return () => {
          subscription.unsubscribe();
        };
      };
  
      const cleanup = setupSubscription();
      
      return () => {
        // Cleanup subscription on unmount
        cleanup.then(unsubscribe => unsubscribe?.());
      };
    }, []);
  
    return { 
      subscriptionStatus, 
      isLoading,
      refreshSubscriptionStatus: fetchSubscriptionStatus 
    };
  };