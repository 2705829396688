import React, { useState, useRef, useEffect } from 'react';
import { Volume2, VolumeX, Repeat, Mic, Loader2, Send, RotateCcw, Pause, Play } from 'lucide-react';
import { Question } from '../types/types';
import { supabase } from '../lib/supabaseClient';
import { useSubscription } from '../Components/SubscriptionModal'; // Import the hook
import { track } from '../lib/mixpanel';


interface QuestionCardProps {
  question: Question;
  isLoading: boolean;
  isMuted: boolean;
  isAudioFinished: boolean;
  audioRef: React.RefObject<HTMLAudioElement>;
  toggleMute: () => void;
  repeatQuestion: () => void;
  handleAudioEnded: () => void;
  fetchQuestion: () => void;
  audioPermission: boolean | null;
  requestAudioPermission: () => Promise<boolean>;
  onShowSubscriptionModal: () => void;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  isLoading,
  isMuted,
  isAudioFinished,
  audioRef,
  toggleMute,
  repeatQuestion,
  handleAudioEnded,
  fetchQuestion,
  audioPermission,
  requestAudioPermission,
  onShowSubscriptionModal,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const [remainingTime, setRemainingTime] = useState(300); // 5 minutes in seconds
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const streamRef = useRef<MediaStream | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { subscriptionStatus, isLoading: isLoadingSubscription } = useSubscription();

  

   // Add this effect to cleanup recording state when question changes
    // Full cleanup function - only used when changing questions or unmounting
  const fullCleanup = () => {
    if (mediaRecorderRef.current?.state === 'recording') {
      mediaRecorderRef.current.stop();
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => {
        track.stop();
        streamRef.current?.removeTrack(track);
      });
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    setIsRecording(false);
    setIsPaused(false);
    setRecordedBlob(null);
    setRemainingTime(300);
    
    mediaRecorderRef.current = null;
    streamRef.current = null;
    chunksRef.current = [];
  };

  // Cleanup only on unmount or question change
  useEffect(() => {
    return fullCleanup;
  }, []);



  const checkAnswerAccess = () => {
    if (!subscriptionStatus) return false;
    
    if (!subscriptionStatus.first_answer_used) {
      track('Free Answer Used', {
        question_id: question.id,
        job_title: question.job_title,
        company: question.company
      });
      return true;
    }

    return subscriptionStatus.subscription_status === 'active';
  };

  const handleSubmitClick = async () => {
    const hasAccess = checkAnswerAccess();
    
    track('Submit Answer Attempted', {
      question_id: question.id,
      has_access: hasAccess,
      subscription_status: subscriptionStatus?.subscription_status,
     
    });

    if (!hasAccess) {
      onShowSubscriptionModal();
      return;
    }

    await submitAnswer();
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (isRecording && !isPaused) {
      timerRef.current = setInterval(() => {
        setRemainingTime(prev => {
          if (prev <= 1) {
            pauseRecording();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isRecording, isPaused]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const startRecording = async () => {
    track('Recording Start Attempted', {
      question_id: question.id,
      has_audio_permission: audioPermission
    });
  
    if (!audioPermission) {
      const granted = await requestAudioPermission();
      if (!granted) {
        track('Audio Permission Denied', {
          question_id: question.id
        });
        alert("Microphone permission is required to answer the question.");
        return;
      }
    }
  
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      
      // Check if browser supports specific mime types
      const mimeType = getMimeType();
      console.log("Using MIME type:", mimeType); // Debug log
      
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: mimeType
      });
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
          const combinedBlob = new Blob(chunksRef.current, { type: mimeType });
          setRecordedBlob(combinedBlob);
        }
      };
  
      mediaRecorderRef.current.start(100);
      setIsRecording(true);
      setIsPaused(false);
  
      track('Recording Started', {
        question_id: question.id,
        mime_type: mimeType
      });
    } catch (error) {
      track('Recording Error', {
        question_id: question.id,
        error_message: error instanceof Error ? error.message : 'Unknown error'
      });
      console.error("Error starting recording:", error);
      alert("Failed to start recording. Please check your microphone permissions.");
    }
  };

  // Helper function to determine supported mime type
  const getMimeType = () => {
    // Define types in order of preference
    const types = [
      'audio/webm;codecs=opus',
      'audio/webm',
      'audio/mp4',
      'audio/aac',
      'audio/wav'
    ];
    
    // For Safari, prioritize MP4/AAC
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      types.unshift('audio/mp4', 'audio/aac');
    }
    
    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        console.log(`Selected MIME type: ${type}`);
        return type;
      }
    }
    
    throw new Error('No supported audio MIME types found');
  };
  
  // Update the Blob creation to ensure consistent MIME type
  const createAudioBlob = (chunks: Blob[], mimeType: string): Blob => {
    const type = mimeType.split(';')[0]; // Remove codec info for blob creation
    return new Blob(chunks, { type });
  };


  const pauseRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      streamRef.current?.getTracks().forEach(track => track.stop());
      
      setIsPaused(true);
      setIsRecording(false);

      track('Recording Paused', {
        question_id: question.id,
        remaining_time: remainingTime
      });
    }
  };

  const resumeRecording = async () => {
    track('Recording Resume Attempted', {
      question_id: question.id,
    });
  
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      
      const mimeType = getMimeType();
      console.log("Resuming with MIME type:", mimeType);
      
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
          const combinedBlob = createAudioBlob(chunksRef.current, mimeType);
          setRecordedBlob(combinedBlob);
        }
      };
  
      mediaRecorderRef.current.start(100);
      setIsRecording(true);
      setIsPaused(false);
  
      track('Recording Resumed', {
        question_id: question.id,
        remaining_time: remainingTime,
        mime_type: mimeType
      });
    } catch (error) {
      console.error("Error resuming recording:", error);
      alert("Failed to resume recording. Please check your microphone permissions.");
    }
  };

  const restartRecording = () => {
    track('Recording Restart', {
      question_id: question.id,
    });

    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    chunksRef.current = [];
    setRecordedBlob(null);
    setRemainingTime(300);
    setIsPaused(false);
    startRecording();
  };

  const submitAnswer = async () => {
    if (!recordedBlob) {
      track('Submit Error', {
        question_id: question.id,
        error_type: 'no_recording'
      });
      alert('No recording available to submit.');
      return;
    }
  
    setIsProcessing(true);
    const submissionStartTime = Date.now();
  
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('User not authenticated');
      }
  
      track('Answer Submission Started', {
        question_id: question.id,
        audio_size: recordedBlob.size,
        mime_type: recordedBlob.type
      });
  
      const { data: answer, error: insertError } = await supabase
        .from('answers')
        .insert({
          question_id: question.id,
          user_id: user.id,
          status: 'processing',
          audio_url: ''
        })
        .select()
        .single();
  
      if (insertError) throw insertError;
  
      // Convert blob to base64 using FileReader
      const base64Data = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          // Remove the data URL prefix (e.g., "data:audio/webm;base64,")
          const base64 = base64String.split(',')[1];
          resolve(base64);
        };
        reader.onerror = reject;
        reader.readAsDataURL(recordedBlob);
      });
  
      const { error: functionError } = await supabase.functions.invoke('process-answer', {
        body: JSON.stringify({
          answerId: answer.id,
          questionId: question.id,
          userId: user.id,
          audioData: base64Data
        }),
      });
  
      if (functionError) throw functionError;
  
      track('Answer Submission Completed', {
        question_id: question.id,
        answer_id: answer.id,
        submission_duration: Date.now() - submissionStartTime,
      });
  
      setRecordedBlob(null);
      setIsPaused(false);
      chunksRef.current = [];
  
    } catch (error) {
      track('Answer Submission Failed', {
        question_id: question.id,
        error_message: error instanceof Error ? error.message : 'Unknown error',
        submission_attempt_duration: Date.now() - submissionStartTime
      });
      console.error('Error submitting answer:', error);
      alert('Failed to process your answer. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Helper function to convert blob to base64
  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const base64 = base64String.split(',')[1];
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Helper function to convert audio to WAV format
  const convertToWAV = async (blob: Blob): Promise<Blob> => {
    // If the blob is already in a supported format, return it
    if (['audio/wav', 'audio/mp3', 'audio/webm', 'audio/mp4', 'audio/m4a'].includes(blob.type)) {
      return blob;
    }
  
    // Create AudioContext
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    
    // Convert blob to ArrayBuffer
    const arrayBuffer = await blob.arrayBuffer();
    
    // Decode audio data
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
    // WAV file header creation
    const createWAVHeader = (length: number, sampleRate: number, channels: number) => {
      const buffer = new ArrayBuffer(44);
      const view = new DataView(buffer);
  
      // "RIFF" chunk descriptor
      writeString(view, 0, 'RIFF');
      view.setUint32(4, 36 + length * 2, true);
      writeString(view, 8, 'WAVE');
  
      // "fmt " sub-chunk
      writeString(view, 12, 'fmt ');
      view.setUint32(16, 16, true); // Subchunk1Size (16 for PCM)
      view.setUint16(20, 1, true); // AudioFormat (1 for PCM)
      view.setUint16(22, channels, true); // NumChannels
      view.setUint32(24, sampleRate, true); // SampleRate
      view.setUint32(28, sampleRate * channels * 2, true); // ByteRate
      view.setUint16(32, channels * 2, true); // BlockAlign
      view.setUint16(34, 16, true); // BitsPerSample
  
      // "data" sub-chunk
      writeString(view, 36, 'data');
      view.setUint32(40, length * 2, true); // Subchunk2Size
  
      return buffer;
    };
  
    const writeString = (view: DataView, offset: number, string: string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };
  
    // Create WAV file
    const numberOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length;
    const sampleRate = audioBuffer.sampleRate;
  
    // Create WAV header
    const wavHeader = createWAVHeader(length, sampleRate, numberOfChannels);
  
    // Create the audio data
    const wavData = new Float32Array(length * numberOfChannels);
    for (let channel = 0; channel < numberOfChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      for (let i = 0; i < length; i++) {
        wavData[i * numberOfChannels + channel] = channelData[i];
      }
    }
  
    // Convert Float32Array to Int16Array
    const samples = new Int16Array(wavData.length);
    for (let i = 0; i < wavData.length; i++) {
      const s = Math.max(-1, Math.min(1, wavData[i]));
      samples[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
    }
  
    // Combine header and data
    const wavBytes = new Uint8Array(wavHeader.byteLength + samples.byteLength);
    wavBytes.set(new Uint8Array(wavHeader), 0);
    wavBytes.set(new Uint8Array(samples.buffer), wavHeader.byteLength);
  
    // Create WAV blob
    return new Blob([wavBytes], { type: 'audio/wav' });
  };


  return (
    <div className="w-full bg-base-300/30 backdrop-blur-lg border-b border-base-content/10 shadow-lg z-20 relative">
      <div className="px-6 py-8">
        <h2 className="text-2xl font-semibold mb-4">
          {question.job_title} at {question.company}
        </h2>
        {question.status === 'pending' || question.status === 'generating' ? (
          <div className="mb-6">
            <p className="text-gray-600">Generating your interview question. Please wait...</p>
            <progress className="progress w-full mt-4"></progress>
          </div>
        ) : question.status === 'failed' ? (
          <div className="mb-6">
            <p className="text-red-500">An error occurred while generating the question:</p>
            <p className="mt-2 text-gray-700">{question.error_message || 'Unknown error'}</p>
            <button
              className="mt-4 btn btn-primary"
              onClick={fetchQuestion}
            >
              Retry
            </button>
          </div>
        ) : (
          <>
            <p className="text-2xl font-light mb-8 leading-relaxed">{question.question}</p>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
              <div className="relative">
                  {!recordedBlob && !isRecording && !isPaused ? (
                    <button
                      className="btn bg-[#fbc02b] hover:bg-[#fbc02b] text-black border-none shadow-[0_2px_4px_rgba(251,192,43,0.2)] hover:shadow-[0_4px_12px_rgba(251,192,43,0.3)] transition-all duration-200 hover:-translate-y-0.5"
                      onClick={startRecording}
                      disabled={isProcessing}
                      onMouseEnter={() => setShowHint(true)}
                      onMouseLeave={() => setShowHint(false)}
                    >
                      <Mic className="mr-2" />
                      Start Recording
                    </button>
                  ) : (
                    <button
                      className={`btn ${
                        isRecording 
                          ? 'btn-error' 
                          : 'bg-[#fbc02b] hover:bg-[#fbc02b] text-black border-none shadow-[0_2px_4px_rgba(251,192,43,0.2)] hover:shadow-[0_4px_12px_rgba(251,192,43,0.3)] transition-all duration-200 hover:-translate-y-0.5'
                      }`}
                      onClick={isRecording ? pauseRecording : resumeRecording}
                      disabled={isProcessing || remainingTime === 0}
                    >
                      {isRecording ? (
                        <>
                          <Pause className="mr-2" />
                          Pause
                        </>
                      ) : (
                        <>
                          <Play className="mr-2" />
                          Continue Recording
                        </>
                      )}
                    </button>
                  )}
                </div>

                {(isRecording || isPaused || recordedBlob) && (
                  <span className={`ml-2 font-mono ${remainingTime <= 30 ? 'text-error' : ''}`}>
                    {formatTime(remainingTime)}
                  </span>
                )}

                {(recordedBlob || isPaused) && (
                  <>
                    <button
                      className="btn btn-ghost gap-2"
                      onClick={restartRecording}
                      disabled={isProcessing}
                    >
                      <RotateCcw className="mr-2" />
                      Start Over
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleSubmitClick}  // THIS IS CORRECT
                      disabled={isProcessing || isRecording}
                    >
                      {isProcessing ? (
                        <>
                          <Loader2 className="mr-2 animate-spin" />
                          Processing
                        </>
                      ) : (
                        <>
                          <Send className="mr-2" />
                          Submit Answer
                        </>
                      )}
                    </button>
                  </>
                )}
              </div>

              {question.audio_url && (
                <div className="flex items-center space-x-2">
                  <button onClick={toggleMute} className="btn btn-circle btn-primary">
                    {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
                  </button>
                  {isAudioFinished && (
                    <button onClick={repeatQuestion} className="btn btn-circle btn-primary">
                      <Repeat size={24} />
                    </button>
                  )}
                  <audio
                    ref={audioRef}
                    onEnded={handleAudioEnded}
                    className="hidden"
                  />
                </div>
              )}
            </div>

            {!audioPermission && (
              <p className="text-yellow-500 mt-4">
                Audio permission is required to play the question. 
                {audioPermission === null ? (
                  <button onClick={requestAudioPermission} className="underline ml-2">
                    Grant Permission
                  </button>
                ) : (
                  "Please enable it in your browser settings."
                )}
              </p>
            )}

{!isLoadingSubscription && 
             subscriptionStatus?.first_answer_used && 
             subscriptionStatus?.subscription_status !== 'active' && 
             recordedBlob && (
              <div className="mt-4 p-4 bg-warning/10 rounded-lg">
                <p className="text-warning">
                  You've recorded your one free answer and received feedback! Subscribe to continue improving.
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionCard;